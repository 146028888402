export class CodeModel {
    constructor(data,typeCode,isUseGift=false) {
        this.id = data?.id
        this.name = data?.name
        this.value = data?.value
        this.start_date = data?.start_date
        this.end_date = data?.end_date
        this.unit = data?.unit
        this.type = data?.type
        this.is_center = data?.is_center
        this.description = data?.description
        this.gift = data?.gift
        this.amount = data?.amount
        this.branch_id = data?.branch_id
        this.is_flash_sale = data?.is_flash_sale
        this.branch = data?.branch
        this.typeCode = data?.program_id

        this.selected = false;
        this.hidden = false;
        this.isUseGift = isUseGift;
        this.program_id = data?.program_id
    }
}