<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Danh sách hợp đồng</h3>
      </div>
      <div class="card-title">
        <div v-if="currentUser.user.permission.includes(permission.EXPORT_DANH_SACH_HOP_DONG)">
          <div class="my-3">
            <b-button id="popover-reactive-1" variant="primary" ref="button" class="mr-2">
              <i class="la la-file-excel"></i> Xuất google sheet
            </b-button>
          </div>
          <b-popover
              target="popover-reactive-1"
              triggers="click"
              :show.sync="popoverShow"
              placement="auto"
              container="my-container"
              ref="popover"
              @show="onShow"
              @hidden="onHidden"
          >
            <template #title>
              <b-button @click="onClose" class="close" aria-label="Close">
                <span class="d-inline-block" aria-hidden="true">&times;</span>
              </b-button>
              Dánh sách thư mục
            </template>

            <div>
              <b-form-group
                  label-for="popover-input-1"
                  label-cols="3"
                  :state="validateState"
                  class="mb-1"
                  invalid-feedback="Không được để trống"
                  size="lg"
              >
                <b-form-select
                    id="popover-input-1"
                    v-model="folderData"
                    :state="validateState"
                    :options="options"
                    size="sm"
                    class="mb-3"
                ></b-form-select>
              </b-form-group>
              <b-button @click="onClose" size="sm" variant="danger">Cancel</b-button>
              <b-button @click="onOk" size="sm" variant="primary" class="ml-2">Ok</b-button>
            </div>
          </b-popover>
          <button v-if="is_export_sheet" disabled
                  class="btn btn-primary font-weight-bold mr-2 spinner spinner-white spinner-left">
            Xuất google sheet
          </button>
        </div>
        <div v-if="currentUser.user.permission.includes(permission.EXPORT_DANH_SACH_HOP_DONG)">
          <button v-if="!is_export_excel" class="btn btn-success font-weight-bold mr-2" @click="exportExcel">
            <i class="la la-file-excel"></i> Xuất excel
          </button>
          <button v-else disabled
                  class="btn btn-success font-weight-bold mr-2 spinner spinner-white spinner-left">
            Xuất excel
          </button>
        </div><div v-if="currentUser.user.permission.includes(permission.EXPORT_DANH_SACH_HOP_DONG)">
        <button v-if="!is_export_excel" class="btn btn-success font-weight-bold mr-2" @click="exportExcelV2">
          <i class="la la-file-excel"></i> Xuất excel v2
        </button>
        <button v-else disabled
                class="btn btn-success font-weight-bold mr-2 spinner spinner-white spinner-left">
          Xuất excel v2
        </button>
      </div>
        <div v-if="currentUser.user.permission.includes(permission.EXPORT_DANH_SACH_HOP_DONG)">
          <button v-if="!is_export_excel_197" class="btn btn-success font-weight-bold mr-2" @click="exportExcel197">
            <i class="la la-file-excel"></i> Xuất excel hợp đồng 19-7
          </button>
          <button v-else disabled
                  class="btn btn-success font-weight-bold mr-2 spinner spinner-white spinner-left">
            Xuất excel data 197
          </button>
        </div>
        <router-link
            class="font-weight-bold font-size-3  btn btn-success"
            :to="{ name: 'contract-create' }"
            v-if="currentUser.user.permission.includes(permission.TAO_HD)"
        >Thêm mới
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <div class="example mb-10">
        <div class="row">
          <div class="col-md-2">
            <el-input placeholder="Tên, SĐT, Mã học viên" v-model="query.keyword"></el-input>
            <!--end::Form-->
          </div>
          <div class="col-md-2">
            <el-select v-model="query.range_total_paid" multiple placeholder="Số tiền đóng" clearable>
              <el-option
                  v-for="item in range_total_paid"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="col-md-2">
            <el-select v-model="query.center_ids" clearable filterable multiple collapse-tags placeholder="Trung tâm" @change="changeCenter">
              <el-option
                  v-for="item in centers"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="col-md-2">
            <el-select v-model="query.branch_ids" clearable filterable multiple collapse-tags placeholder="Chi nhánh">
              <el-option
                  v-for="item in branches"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="col-md-2">
            <date-picker v-model="query.range_date" type="date" format="DD-MM-YYYY" value-type="YYYY-MM-DD" range
                         placeholder="Chọn ngày tạo"
            ></date-picker>
          </div>
          <div class="col-md-2">
            <el-select v-model="query.status_contract_cancelled" placeholder="Trạng thái" clearable multiple collapse-tags>
              <el-option
                  v-for="item in status_contract_cancelled"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-2">
            <el-select v-model="query.type" placeholder="Loại hợp đồng" clearable class="w-100">
              <el-option
                  v-for="item in CONTRACT_TYPES"
                  :key="item.value"
                  :label="item.title"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="col-md-2">
            <el-select v-model="query.tuitionPayment" placeholder="Hình thức đóng học phí" clearable class="w-100">
              <el-option
                  v-for="item in tuitionPayments"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="col-md-2">
            <div class="d-flex justify-content-between">
              <button v-if="!is_disable_search" class=" btn btn-primary font-weight-bold mr-2"
                      @click="searchData">
                <i class="fa fa-search"></i> Tìm kiếm
              </button>
              <button v-else type="button" disabled
                      class="btn btn-primary spinner spinner-white spinner-left font-weight-bold mr-2">
                Tìm kiếm
              </button>
            </div>
          </div>
        </div>
        <div class="example-preview table-responsive overflow-auto mt-3">
          <table :aria-busy="isBusy" class="table table-hover table-bordered table-vertical-center b-table"
                 style="text-align: center">
            <thead>
            <tr>
              <th scope="col" class="min-w-30px">STT</th>
              <th scope="col" class="min-w-100px">Thời gian tạo</th>
              <th scope="col" class="min-w-100px">Mã học viên</th>
              <th scope="col" class="min-w-100px">Mã hợp đồng</th>
              <th scope="col" class="min-w-100px">Tên khách hàng</th>
              <th scope="col" class="min-w-120px">Số điện thoại</th>
              <th scope="col" class="min-w-120px">Họ tên người giám hộ</th>
              <th scope="col" class="min-w-120px">Số điện thoại người giám hộ</th>
              <th scope="col" class="min-w-120px">Số tiền phải đóng</th>
              <th scope="col" class="min-w-120px">Đã đóng</th>
              <th scope="col" class="min-w-120px">Hình thức đóng học phí</th>
              <th scope="col" class="min-w-120px">Trạng thái</th>
              <th scope="col" class="min-w-100px">Hành động</th>
            </tr>
            </thead>
            <tbody v-if="isBusy">
              <tr role="row" class="b-table-busy-slot" >
                <td colspan="16" role="cell" class="">
                  <div class="text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </td>
              </tr>

            </tbody>
            <tbody v-else>
            <tr v-for="(item, index) in contracts.data" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.created_at | formatDate }}</td>
              <td>{{ item.user != null ? item.user.id + 100000 : '' }}</td>
              <td>{{ item.id }}</td>
              <td>{{ item.customer ? item.customer?.name : item.user?.name}}</td>
              <td>{{ item.customer ? item.customer?.phone : item.user?.phone }}</td>
              <td>{{ item.user_parent && item.user_parent.parent ? getUserParent(item.user_parent) : '' }}</td>
              <td>{{ item.user_parent && item.user_parent.parent ? item.user_parent.parent.phone : '' }}</td>
              <td>{{ formatPrice(item.total_after_voucher, 'đ') }}</td>
              <td>{{ formatPrice(item.total_paid, 'đ') }}</td>
              <td>{{ (item.tuition_payment_id != null && item.tuition_payment_id != 0) ? filterTuitionPayment(item.tuition_payment_id) : '' }}</td>
              <td>{{ checkStatus(item.confirm, item.is_cancel, item.total_paid ) }}</td>
              <td class="d-flex justify-content-start pl-xl-14">
              <td class="d-flex justify-content-around">
                <a href="javascript:" title="Xem chi tiết"
                   class="btn btn-xs btn-icon btn-outline-success"
                   v-if="currentUser.user.permission.includes(permission.CHI_TIET_HD)"
                   @click="viewContract(item.id)"><i class="far fa-eye"></i>
                </a>
                <a href="javascript:" title="Sửa hợp đồng" @click="showDetail(item)"
                   v-if="item.type === 1 && currentUser.user.permission.includes(permission.SUA_HD)"
                   class="btn btn-xs btn-icon btn-outline-info ml-2">
                    <i class="fas fa-pen-nib"></i>
                </a>
                <a v-if="!item.total_paid && !item.is_cancel && currentUser.user.permission.includes(permission.XOA_HD)"
                   title="Hủy"
                   @click="deleteContract(item.id)"
                   href="javascript:"
                   class="btn btn-xs btn-icon btn-outline-danger ml-2"><i class="fas fa-trash"></i></a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <ContractForm :showModal="showModalContract"
                      :contract="contract"
                      :editBranch="editBranch"
                      :editName="editName"
                      :editEmail="editEmail"
                      :editPhone="editPhone"
                      :editBirthDate="editBirthDate"
                      :listRefundByBranch="listRefundByBranch"
                      from-page="danh-sach-hd"
                      :updateContract="true"
                      @clicked="onclickContractDetail"></ContractForm>
        <ContractDetail :showModal="showDetailContract" :contract="contract"
                        @clicked="onclickViewContractDetail"></ContractDetail>
      </div>
    </div>

    <div class="edu-paginate mx-auto text-center">
      <paginate
          v-model="page"
          :page-count="last_page"
          :page-range="3"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
          :page-class="'page-item'"
      >
      </paginate>
    </div>

  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {GET_FOLDERS_DRIVERS} from "@/core/services/store/public/google.module";

const _ = require('lodash');
import Swal from "sweetalert2";
import {
  GET_CONTRACT_BY_ID,
  GET_CONTRACTS,
  DELETE_CONTRACT,
  EXPORT_EXCEL_CONTRACTS,
  EXPORT_CONTRACT_GOOGLE_SHEET, EXPORT_EXCEL_197_CONTRACTS
} from "@/core/services/store/contract/contracts.module";
import {GET_TUITION_PAYMENT} from "@/core/services/store/installment/installment.module";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
// Datetime picker
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
/*Show modal edit*/
import ContractForm from "@/view/pages/business/ContractForm";
import ContractDetail from "@/view/pages/business/ContractDetail";
import {GET_CENTERS} from "@/core/services/store/center/centers.module";
import {GET_BRANCHES} from "@/core/services/store/center/branch.module";
import {CONTRACT_TYPES} from "@/core/option/contractOption"
import {exportExcel} from "../../../core/filters";
import {EXPORT_EXCEL_CONTRACTS_V2} from "../../../core/services/store/contract/contracts.module";
/*End*/

export default {
  components: {
    DatePicker,
    ContractForm,
    ContractDetail,
  },
  name: "ContractIndex",
  data() {
    return {
      /*Loader */
      isLoading: false,
      fullPage: true,
      /* popover */
      path: '',
      showPop: false,
      folderData: '',
      validateState: null,
      options: [],
      popoverShow: false,
      /*Show modal edit*/
      center_classes: [],
      contract: {},
      showModalContract: false,
      showDetailContract: false,
      courses: [],
      center_id: '',
      /*End*/
      is_disable_search: false,
      is_export_excel: false,
      is_export_excel_197: false,
      is_export_sheet: false,
      isBusy: false,
      benThuTien: [
        {
          name: '__Chọn__'
        },
        {
          id: 0,
          name: 'Trung tâm'
        },
        {
          id: 1,
          name: 'Edutalk'
        }
      ],
      range_total_paid: [
          {
              value: 0,
              label: 'Chưa đóng tiền'
          },
          {
              value: 1,
              label: 'Dưới 1.200.000đ'
          },
          {
              value: 2,
              label: 'Từ 1.200.000đ trở lên'
          }
      ],
      status_contract_cancelled: [
        {
          value: 0,
          label: 'Chờ xác thực'
        },
        {
          value: 1,
          label: 'Đã xác thực'
        },
        {
          value: 2,
          label: 'Đã hủy'
        },
        {
          value: 3,
          label: 'Đã đóng tiền'
        }
      ],
      CONTRACT_TYPES,
      centers: [],
      branches: [],
      query: {
        keyword: '',
        range_total_paid: '',
        center_ids: '',
        branch_ids: '',
        range_date: [],
        status_contract_cancelled: [],
        type: 1,
        tuitionPayment: '',
      },
      contracts: [],
      // Paginate
      page: 1,
      last_page: 1,
      editName: false,
      editEmail: false,
      editPhone: false,
      editBranch: false,
      editBirthDate: false,
      listRefundByBranch:[],
      tuitionPayments: [],
    }
  },
  watch: {
    folderData(val) {
      if (val) {
        this.validateState = true;
        this.path = val;
      }
    },
  },
  created() {
    this.getFolders();
    this.getList();
    this.getCenters();
    this.getTuitionPayments();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý hợp đồng", route: '#'},
      {title: "Danh sách hợp đồng"}
    ]);
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    filterTuitionPayment(tuitionPaymentId) {
      let tuition = this.tuitionPayments.filter(function (tuitionPayment) {
        return tuitionPayment.id == tuitionPaymentId
      })
      return tuition.length > 0 ? tuition[0].name : ''
    },
    getTuitionPayments() {
      this.$store.dispatch(GET_TUITION_PAYMENT).then((response) => {
        this.tuitionPayments = response.data
      });
    },
    onCancel: function () {
      console.log('User cancelled the loader.')
    },
    onClose() {
      this.popoverShow = false
    },
    onOk() {
      if (!this.folderData) {
        this.validateState = false
      }
      if (this.folderData) {
        this.onClose()
        // Return our popover form results
        this.exportGoogleSheet();
      }
    },
    onShow() {
      this.folderData = ''
      this.validateState = null
    },
    onHidden() {
      this.focusRef(this.$refs.button)
    },
    focusRef(ref) {
      this.$nextTick(() => {
        this.$nextTick(() => {
          ;(ref.$el || ref).focus()
        })
      })
    },
    getFolders: function () {
      this.$store.dispatch(GET_FOLDERS_DRIVERS).then((res) => {
        this.options = _.reduce(res.data, function (result, item, key) {
          result.push({
            text: item.name,
            value: item.path
          });
          return result;
        }, [])
        this.options.unshift({text: '- Chọn thư mục -', value: ''});
      });

    },
    /*Show modal edit contract*/
    changeCenter() {
      this.getBranches();
    },
    getCenters: async function () {
      await this.$store.dispatch(GET_CENTERS, {
        limit: 10000,
      }).then((res) => {
        this.centers = _.map(res.data.data, (item) => {
          item = {value: item.id, label: item.name};
          return item;
        })
      });
    },
    getBranches: async function () {
      await this.$store.dispatch(GET_BRANCHES, {
        limit: 10000,
        center_ids: this.query.center_ids
      }).then((res) => {
        this.branches = _.map(res.data.data, (item) => {item = {value: item.id, label: item.name};
          return item;
        })
      });
    },
    showDetail: async function (contract) {
      let contract_id = contract.id;
      await this.getContractDetail(contract_id);
      this.showModalContract = true;
    },
    viewContract: async function (contract_id) {
      await this.getContractDetail(contract_id);
      this.showDetailContract = true;
    },
    getContractDetail: async function (contract_id) {
      await this.$store.dispatch(GET_CONTRACT_BY_ID, contract_id).then((res) => {
        this.contract = res.data;
        this.showEdit(this.contract);
      });
    },
    onclickContractDetail() {
      this.getList()
      this.showModalContract = false;
    },
    onclickViewContractDetail() {
      this.showDetailContract = false;
    },
    /*End*/
    getList() {
      this.pullParamsUrl()
      this.callApiGetList()
    },
    async pullParamsUrl() {
      if (typeof this.$route.query.page == "string") {
        this.page = parseInt(this.$route.query.page)
      }
      this.query.keyword = this.$route.query.keyword;
      this.query.range_total_paid = this.$route.query.range_total_paid;
      this.query.center_ids = this.$route.query.center_ids;
      this.query.branch_ids = this.$route.query.branch_ids;
      this.query.range_date = this.$route.query.range_date;
      this.query.status_contract_cancelled = this.$route.query.status_contract_cancelled ? this.$route.query.status_contract_cancelled : [];
      this.query.type = this.$route.query.type ? Number(this.$route.query.type) : '';
      this.query.tuitionPayment = this.$route.query.tuitionPayment ? parseInt(this.$route.query.tuitionPayment) : ""
      this.convertStringToInForArray();
    },
    convertStringToInForArray(){
      this.query.status_contract_cancelled = this.query.status_contract_cancelled.map(function (x) {
        return parseInt(x, 10);
      });

    },
    pushParamsUrl() {
      this.$router.push({
        name: 'contract-index',
        query: {
          page: this.page,
          ...this.query
        }
      })
    },
    clickCallback(obj) {
      this.page = obj
      this.$router.push({
        name: 'contract-index',
        query: {
          page: this.page,
          ...this.query
        }
      })
      this.callApiGetList()
    },
    callApiGetList() {
      this.isBusy = true;
      let payload = {...this.query, page: this.page}
      if (this.query.range_date != undefined && this.query.range_date.length) {
        payload.start_date = this.query.range_date[0];
        payload.end_date = this.query.range_date[1];
      }
      this.$store.dispatch(GET_CONTRACTS, payload).then((res) => {
        this.isBusy = false;
        this.contracts = res.data
        this.is_disable_search = false
        this.last_page = res.data.last_page
      })
    },
    exportExcelV2() {
      this.is_export_excel = true
      let params = {...this.query}
      if (this.query.range_date != undefined && this.query.range_date.length) {
        params.start_date = this.query.range_date[0];
        params.end_date = this.query.range_date[1];
      }
      const HEADER_ROW_EXPORT_CONTRACT_LIST = [
        {
          key: 'id',
          value: 'Mã HĐ',
        },
        {
          key: 'created_at',
          value: 'Ngày tạo',
        },
        {
          key: 'name',
          value: 'Họ tên khách hàng',
        },
        {
          key: 'email',
          value: 'Email khách hàng',
        },
        {
          key: 'phone',
          value: 'Số điện thoại khách hàng',
        },
        {
          key: 'birthday',
          value: 'Ngày sinh',
        },
        {
          key: 'center_id',
          value: 'ID Trung tâm',
        },
        {
          key: 'center_name',
          value: 'Tên Trung tâm',
        },
        {
          key: 'branch_id',
          value: 'ID Chi nhánh',
        },
        {
          key: 'branch_name',
          value: 'Tên Chi nhánh',
        },
        {
          key: 'id_courses',
          value: 'ID khóa học',
        },
        {
          key: 'name_courses',
          value: 'Khóa học',
        },
        {
          key: 'promotion_name',
          value: 'Mã combo',
        },
        {
          key: 'promotion_value',
          value: 'Giảm giá combo',
        },
        {
          key: 'voucher_name',
          value: 'Danh sách vouchers',
        },
        {
          key: 'voucher_value',
          value: 'Giảm giá vouchers',
        },
        {
          key: 'total_fee_courses',
          value: 'Tổng giá khóa (1)',
        },
        {
          key: 'total_voucher',
          value: 'Tổng khuyến mại (2)',
        },
        {
          key: 'total_after_voucher',
          value: 'Tổng giá trị hợp đồng (3) = (1) + (2)',
        },
        {
          key: 'total_paid',
          value: 'Đã thanh toán (4): Tổng nạp - tổng rút',
        },
        {
          key: 'total_debt',
          value: 'Còn nợ (5) = (3) - (4)',
        },
        {
          key: 'tuition_payment',
          value: 'Hình thức hoàn thành học phí',
        },
        {
          key: 'status',
          value: 'Trạng thái',
        },
        {
          key: 'type',
          value: 'Loại hợp đồng',
        },
        {
          key: 'gift',
          value: 'Quà tặng',
        },
        {
          key: 'gift_detail',
          value: 'Chi tiết quà tặng',
        },
        {
          key: 'tvv_id',
          value: 'Mã tư vấn',
        },
        {
          key: 'user_intro_id',
          value: 'Mã giới thiệu',
        }
      ]
      this.$store.dispatch(EXPORT_EXCEL_CONTRACTS_V2, params).then((res) => {
        this.is_export_excel = false
        if (res.data.error) {
          this.$bvToast.toast(res.data.message, {
            title: 'Export excel',
            variant: 'danger',
            solid: true
          });
        } else {
          exportExcel(HEADER_ROW_EXPORT_CONTRACT_LIST, res.data, "Danh sách hợp đồng.xlsx", false)
        }
      });
    },
    exportExcel() {
      this.is_export_excel = true
      let params = {...this.query}
      if (this.query.range_date != undefined && this.query.range_date.length) {
        params.start_date = this.query.range_date[0];
        params.end_date = this.query.range_date[1];
      }
      const HEADER_ROW_EXPORT_CONTRACT_LIST = [
        {
          key: 'id',
          value: 'Mã HĐ',
        },
        {
          key: 'created_at',
          value: 'Ngày tạo',
        },
        {
          key: 'name',
          value: 'Họ tên khách hàng',
        },
        {
          key: 'email',
          value: 'Email khách hàng',
        },
        {
          key: 'phone',
          value: 'Số điện thoại khách hàng',
        },
        {
          key: 'birthday',
          value: 'Ngày sinh',
        },
        {
          key: 'center_id',
          value: 'ID Trung tâm',
        },
        {
          key: 'center_name',
          value: 'Tên Trung tâm',
        },
        {
          key: 'branch_id',
          value: 'ID Chi nhánh',
        },
        {
          key: 'branch_name',
          value: 'Tên Chi nhánh',
        },
        {
          key: 'id_courses',
          value: 'ID khóa học',
        },
        {
          key: 'name_courses',
          value: 'Khóa học',
        },
        {
          key: 'promotion_name',
          value: 'Mã combo',
        },
        {
          key: 'promotion_value',
          value: 'Giảm giá combo',
        },
        {
          key: 'voucher_name',
          value: 'Danh sách vouchers',
        },
        {
          key: 'voucher_value',
          value: 'Giảm giá vouchers',
        },
        {
          key: 'total_fee_courses',
          value: 'Tổng giá khóa (1)',
        },
        {
          key: 'total_voucher',
          value: 'Tổng khuyến mại (2)',
        },
        {
          key: 'total_after_voucher',
          value: 'Tổng giá trị hợp đồng (3) = (1) + (2)',
        },
        {
          key: 'total_paid',
          value: 'Đã thanh toán (4): Tổng nạp - tổng rút',
        },
        {
          key: 'total_debt',
          value: 'Còn nợ (5) = (3) - (4)',
        },
        {
          key: 'tuition_payment',
          value: 'Hình thức hoàn thành học phí',
        },
        {
          key: 'status',
          value: 'Trạng thái',
        },
        {
          key: 'type',
          value: 'Loại hợp đồng',
        },
        {
          key: 'gift',
          value: 'Quà tặng',
        },
        {
          key: 'gift_detail',
          value: 'Chi tiết quà tặng',
        },
        {
          key: 'tvv_id',
          value: 'Mã tư vấn',
        },
        {
          key: 'user_intro_id',
          value: 'Mã giới thiệu',
        }
      ]
      this.$store.dispatch(EXPORT_EXCEL_CONTRACTS, params).then((res) => {
        this.is_export_excel = false
        if (res.data.error) {
          this.$bvToast.toast(res.data.message, {
            title: 'Export excel',
            variant: 'danger',
            solid: true
          });
        } else {
          exportExcel(HEADER_ROW_EXPORT_CONTRACT_LIST, res.data, "Danh sách hợp đồng.xlsx", false)
        }
      });
    },
    exportExcel197() {
      this.is_export_excel_197 = true;
      let params = {...this.query}
      this.$store.dispatch(EXPORT_EXCEL_197_CONTRACTS, params).then((res) => {
      }).catch((error) => {
        let messageErr = error.data?.message ? error.data.message : error.data.message_export_file;
        this.$notify({
          title: 'Thành công',
          message: messageErr,
          type: 'error'
        });
      }).finally(() => {
        this.is_export_excel_197 = false;
      });
    },
    exportGoogleSheet() {
      this.isLoading = true
      let params = {...this.query, path: this.path}
      if (this.query.range_date != undefined && this.query.range_date.length) {
        params.start_date = this.query.range_date[0];
        params.end_date = this.query.range_date[1];
      }
      this.$store.dispatch(EXPORT_CONTRACT_GOOGLE_SHEET, params).then((res) => {
        this.isLoading = false
        if (res.error) {
          this.$bvToast.toast(res.message, {
            title: 'Export google sheet',
            variant: 'danger',
            solid: true
          });
        } else {
          this.$bvToast.toast(res.message, {
            title: 'Export google sheet',
            variant: 'success',
            solid: true
          });
        }
      }).catch((e) => {
        if (e.data.error) {
          this.$bvToast.toast(e.data.message, {
            title: 'Export google sheet',
            variant: 'danger',
            solid: true
          });
        }
      });
    },
    searchData() {
      this.page = 1
      this.is_disable_search = true
      this.pushParamsUrl()
      this.callApiGetList()
    },
    deleteContract(id) {
      Swal.fire({
        title: "Bạn chắc chắn muốn huỷ?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch(DELETE_CONTRACT, id).then((res) => {
            Swal.fire("Hủy", "", "success");
            this.getList();
          }).catch((error) => {
            this.$bvToast.toast(error.message, {
              title: 'Xóa',
              variant: 'danger',
              solid: true
            });
          });
        }
      });
    },
    showEdit(contract) {
      let branch = contract.branch ? contract.branch.id : 0;
      let total_paid = contract.total_paid;
      let total = contract.total;
      this.editBranch = false;
      switch (true) {
        case total_paid && total_paid < total && branch > 0 :
          this.editBranch = true;
          break;
        case total_paid && total_paid < total && branch == 0 :
          this.editBranch = false;
          break;
        case total_paid == total && branch == 0 :
          this.editBranch = false;
          break;
        case total_paid == total && branch > 0 :
          this.editBranch = true;
          break;
        case total_paid == 0 && branch == 0 :
          this.editBranch = true;
          break;
      }
    },
    checkStatus(confirm, is_cancel , totalPaid) {
      let text;
      if (totalPaid > 0) {
        text = 'Đã đóng tiền';
      }
      if(confirm == 1 && totalPaid <= 0) {
        text = 'Đã xác thực';
      } else if (totalPaid <= 0) {
        text = 'Chờ xác thực';
      }
      if (is_cancel == 1) {
        text = 'Đã hủy';
      }
      return text;
    },
    changeTotal(course) {
      let total = 0;
      let fee_sale = 0;
      course.map(function (value) {
        if (value.sale) {
          let sale = value.fee / value.sale;
          fee_sale = value.fee - sale;
          total += fee_sale;
        } else {
          total += value.fee;
        }
      });
      return total;
    },
    changeVoucher(voucher) {
      let total = 0;
      voucher.map(function (value) {
        total += value.value;
      });
      return total;
    },
    getUserParent(user_parent) {
      if (user_parent.user && user_parent.user.user_application) {
        return user_parent.user.user_application.name;
      }
      return user_parent.parent.name;
    }
  }
}
</script>
<style>
.mx-datepicker {
  width: 100% !important;
}

#btn-export-excel {
  position: relative;
}

.col-form-label {
  display: none;
}
.div el-dialog__body {
  color: #f5f7fa;
}
</style>
