<template>
  <div>
    <div v-if="promotions.length > 0">
      <div class="row mt-5 border border-primary" v-for="(promotion,index) in promotions" :key="index"
           style="backgroundImage : linear-gradient(to bottom, #DFEAFF,#FFFFFF) "
      >
        <div class="col-md-3">
          <div style="width:95px ; height:100%; position: relative; background-color: #3254CB "
               class="   d-flex align-items-center justify-content-center"
          >
            <div class="mt-4 "  style="width: fit-content; text-align: center !important;">
              <i class="el-icon-s-management " style="font-size: 30px; color: white"></i>
              <p class="mt-2" style="color: white ; font-size: 13px">Quà tặng</p>
            </div>
          </div>
        </div>
        <div class="col-md-6 mt-5">
          <div><span>Tên : {{ promotion.name }}</span></div>
          <div><span>Giảm ngay : {{ promotion.value | toThousandFilter }} ₫</span></div>
          <a href="javascript:" @click="showPromotionDetail(promotion)"><strong>Xem chi tiết</strong></a>
        </div>
        <div class="col-md-3 mt-5">
          <button class="btn "
                  @click="setPromotionForContract(promotion)"
                  :disabled="id_promotion_disable === promotion.id"
                  style="background-color: #472F92; color: white ; width: 130px">
            Nhận quà
          </button>
          <button class="btn  mt-2" style="border-color: #472F92; color: #472F92; background-color: white;width: 130px"
          >Quy đổi
            voucher
          </button>
          <button class="btn " style="background: #A70000;color: white;width: 130px" >
            Hủy áp dụng
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="text-center w-100 center_important">
          <span class="text-danger">
          Không có mã khuyến mãi nào được tìm thấy tương ứng với trung tâm, khóa học bạn chọn
        </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListVouchers",
  props: {
    promotions: {
      type: Array,
      default: () => {
        return []
      }
    },
    id_promotion_disable: {
      type: [String, Number, Array],
      default: () => {
        return []
      }
    }
  },
  methods: {
    showPromotionDetail(promotion) {
      this.$emit('showPromotionDetail', promotion);
    },
    setPromotionForContract(promotion) {
      this.$emit('setPromotionForContract', promotion);
    },

  }
}
</script>

<style scoped>

</style>
